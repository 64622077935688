import React from 'react'
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import FontIcon from "react-md/lib/FontIcons/FontIcon";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../../layout'

const ThankYou = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "thankyou.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Img fluid={data.Image.childImageSharp.fluid} />
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="We have received your Submission!" />
              <CardText>
                <p className="md-text-justify">
                  We have received your form and will get back to you asap.
                  You should have received a copy of the filled form in your email.
                  If you have not received it or do not receive it within a few hours, please contact us at
                  &nbsp;
                  <FontIcon primary forceSize iconClassName="fa fa-envelope" />
                  &nbsp;
                  <a href="mailto:admissions@mindfulmontessori.com">admissions@mindfulmontessori.com</a>
                </p>
                <br />
                <p>Thank you!</p>
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default ThankYou
